import * as React from "react";
// @ts-ignore
import {Col, Container, Form, Row, Button} from "react-bootstrap";

import * as style from './Table.module.scss';
import * as navStyle from '../../../../components/Navigation/Navigation.module.scss';
import * as contentStyle from '../../../../components/Content/Content.module.scss';
import {formButton, formInput, formLabel} from "../../../login/index.module.scss";
import {faArrowRight} from "@fortawesome/pro-light-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {StaticImage} from "gatsby-plugin-image";
import * as mainStyle from "../../index.module.scss";

type TableProps = {
    title: string,
    description: string,
    mediaPosLeft: string,
    image: string,

    listItem1Headline: string,
    listItem2Headline: string,
    listItem3Headline: string,
    listItem4Headline: string,

    listItem1Description: string,
    listItem2Description: string,
    listItem3Description: string,
    listItem4Description: string,
}


function Table(props: TableProps) {
    return <>
        <Row className={mainStyle.box}>
            <Col lg={6} className={props.mediaPosLeft ? `order-lg-last order-last ` + style.paddingLeft : style.paddingRight + " order-last"}>
                <div className={contentStyle.contentElement + " "+ style.contentContainer}>
                    <h2>{props.title}</h2>
                    <div className={style.description}>{props.description}</div>
                </div>

                <Row className={style.listContainer}>
                    <Col xl={6}  className={"d-flex mb-5 " + style.itemContainer}>
                        <div className={style.customBorder}/>
                        <div className={style.listItem}>
                            <h3 className={style.listHeadline}>
                                {props.listItem1Headline}
                            </h3>
                            <div className={style.listDescription}>
                                {props.listItem1Description}
                            </div>
                        </div>
                    </Col>
                    <Col xl={6} className={"d-flex mb-5 " + style.itemContainer}>
                        <div className={style.customBorder}/>
                        <div className={style.listItem}>
                            <h3 className={style.listHeadline}>
                                {props.listItem2Headline}
                            </h3>
                            <div className={style.listDescription}>
                                {props.listItem2Description}
                            </div>
                        </div>
                    </Col>
                    <Col xl={6} className={"d-flex mb-5 " + style.itemContainer}>
                        <div className={style.customBorder}/>
                        <div className={style.listItem}>
                            <h3 className={style.listHeadline}>
                                {props.listItem3Headline}
                            </h3>
                            <div className={style.listDescription}>
                                {props.listItem3Description}
                            </div>
                        </div>
                    </Col>
                    <Col xl={6} className={"d-flex mb-5 " + style.itemContainer}>
                        <div className={style.customBorder}/>
                        <div className={style.listItem}>
                            <h3 className={style.listHeadline}>
                                {props.listItem4Headline}
                            </h3>
                            <div className={style.listDescription}>
                                {props.listItem4Description}
                            </div>
                        </div>
                    </Col>
                </Row>

            </Col>

            <Col lg={6} className={props.mediaPosLeft ? style.paddingRight : style.paddingLeft}>
                <div className={"position-relative"}>
                    <img src={props.image} className={style.image}/>
                    <StaticImage src={"https://api.fairstaerkt.at/assets/LandingPageIcons/badge_selber_anpassen.png"} className={style.imageOnTop}
                                 placeholder={"traceSVG"} alt={"background"}/>
                </div>
            </Col>
        </Row>
    </>
        ;
}

export default Table;
