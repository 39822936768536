// extracted by mini-css-extract-plugin
export var contentContainer = "Table-module--contentContainer--o3Gz+";
export var customBorder = "Table-module--customBorder--GGiqT";
export var description = "Table-module--description--pDG10";
export var image = "Table-module--image--plA3R";
export var imageOnTop = "Table-module--imageOnTop--R0bXF";
export var itemContainer = "Table-module--itemContainer--TBqZV";
export var listDescription = "Table-module--listDescription--La+Wh";
export var listHeadline = "Table-module--listHeadline--4MjnR";
export var listItem = "Table-module--listItem--5i4MR";
export var paddingLeft = "Table-module--paddingLeft--6ixy2";
export var paddingRight = "Table-module--paddingRight--KjlFi";